import SvgColor from '../components/svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export const USA_STATES = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
];

export const CLIENT_SIDEBAR_ITEMS = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: icon('ic_analytics'),
  },
  {
    title: 'DMR dashboard',
    path: '/dmr-dashboard',
    icon: icon('ic_analytics'),
  },
  {
    title: 'DMR Analysis',
    path: '/dmr-analysis',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Agreements',
    path: '/client-contracts',
    icon: icon('ic_lock'),
  },
  // {
  //   title: 'Sign Agreement',
  //   path: '/sign-agreement',
  //   icon: icon('ic_lock'),
  // },
  {
    title: 'Practice Information',
    path: '/practice-info',
    icon: icon('ic-useraccount'),
  },
  {
    title: 'Support Tickets',
    path: '/support-tickets',
    icon: icon('ic_questions'),
  },
];

export const ASSOCIATE_SIDEBAR_ITEMS = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: icon('ic_analytics'),
  },
  {
    title: 'agreements',
    path: '/contracts',
    icon: icon('ic_lock'),
  },
];

export const ADMIN_SIDEBAR_ITEMS = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Client Claims',
    path: '/dmr-page',
    icon: icon('ic_lock'),
  },
  {
    title: 'agreements',
    path: '/contracts',
    icon: icon('ic_lock'),
  },
  // {
  //   title: 'VeriDoc',
  //   path: '/veridoc/new',
  //   icon: icon('ic_lock'),
  // },
  {
    title: 'Clients',
    path: 'clients',
    icon: icon('ic_user'),
  },
  // {
  //   title: 'Audit Trail',
  //   path: '/audit-trail',
  //   icon: icon('ic_vector'),
  // },
  {
    title: 'Support Tickets',
    path: '/support-tickets',
    icon: icon('ic_questions'),
  },
];
