import { Card, Grid, Typography, Select, MenuItem, InputLabel, FormControl, Input } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { claimFile, getClients } from '../services/api';

export default function DmrPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState('');

  useEffect(() => {
    getClients().then((response) => {
      setClients(response.data);
    });
  }, []);

  const handleClientChange = (event) => {
    setSelectedClient(event.target.value);
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];

    const validFileType =
      uploadedFile &&
      (uploadedFile.name.endsWith('.xlsx') || uploadedFile.name.endsWith('.xls') || uploadedFile.name.endsWith('.csv'));

    if (!validFileType) {
      setFileError('Invalid file type. Only excel and csv files are allowed.');
      setFile(null);
      return;
    }

    if (uploadedFile.size > 5 * 1024 * 1024) {
      setFileError('File size exceeds 5 MB');
      setFile(null);
      return;
    }

    setFile(uploadedFile);
    setFileError('');
  };

  const uploadClaimFile = async () => {
    setIsLoading(true);
    try {
      const resp = await claimFile(file, selectedClient);
      const message = resp.data?.message;
      toast.success(message);
    } catch (error) {
      console.log(error);
      toast.error('Unable to process the claims file. Try again later');
    } finally {
      setIsLoading(false);
      setFile(null);
      setSelectedClient('');
    }
  };

  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | Clients</title>
      </Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h4" gutterBottom>
            Client Claims
          </Typography>

          <Card sx={{ p: 3, maxWidth: '700px' }}>
            <FormControl fullWidth sx={{ mb: 4 }}>
              <InputLabel id="client-select-label">Select Client</InputLabel>
              <Select
                labelId="client-select-label"
                id="client-select"
                value={selectedClient}
                label="Select Client"
                onChange={handleClientChange}
              >
                {clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.practice_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography>Upload Claims File (excel or csv)</Typography>

            <FormControl fullWidth sx={{ mb: 4 }}>
              <Input
                id="file-input"
                type="file"
                onChange={handleFileChange}
                inputProps={{ accept: '.xlsx, .xls, .csv' }}
              />
              {fileError && (
                <Typography color="error" variant="body2">
                  {fileError}
                </Typography>
              )}
            </FormControl>

            <LoadingButton
              variant="contained"
              color="primary"
              disabled={!selectedClient || !file || isLoading}
              onClick={uploadClaimFile}
              loading={isLoading}
            >
              Submit
            </LoadingButton>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
