import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import PercentIcon from '@mui/icons-material/Percent';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';

const DmrAnalysis = () => {
  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [allDoctors, setAllDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState('');

  const handleDoctorChange = (event) => {
    setSelectedDoctor(event.target.value);
  };

  const handleReset = () => {
    setSelectedDoctor('');
  };

  const cardsData = [
    {
      id: 1,
      title: 'Total Insurance Payments',
      description: '$0',
      icon: <LocalAtmIcon />,
    },
    {
      id: 2,
      title: 'Total Paid Claims',
      description: '$0',
      icon: <LocalAtmIcon />,
    },
    {
      id: 3,
      title: 'Percentage of Claims Paid',
      description: 0,
      icon: <PercentIcon />,
    },
    { id: 4, title: 'Percentage of Paid Amount', description: 0, icon: <PercentIcon /> },
    {
      id: 5,
      title: 'Percentage of Paid Claims',
      description: 0,
      icon: <PercentIcon />,
    },
    {
      id: 5,
      title: 'Percentage of Denied Claims',
      description: 0,
      icon: <PercentIcon />,
    },
  ];
  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | DMR Analysis</title>
      </Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Box mb={2}>
            <Typography variant="h4" gutterBottom>
              DMR Analysis
            </Typography>
          </Box>

          <Stack direction={'row'} alignItems={'center'} gap={6} mb={2} paddingX={2}>
            <Box display="flex" sx={{ width: '100%' }} gap={4} alignItems={'center'}>
              <Box minWidth={'180px'}>
                <FormControl fullWidth>
                  <InputLabel id="doctor-label">Doctor</InputLabel>
                  <Select
                    labelId="doctor-label"
                    id="doctor-select"
                    value={selectedDoctor}
                    label="Doctor"
                    onChange={handleDoctorChange}
                    MenuProps={{
                      PaperProps: { style: { maxHeight: 300 } },
                    }}
                  >
                    {filterLoading ? (
                      <MenuItem disabled>
                        <Box display="flex" alignItems="center">
                          <CircularProgress size={20} />
                          <Typography sx={{ marginLeft: 1 }}>Loading...</Typography>
                        </Box>
                      </MenuItem>
                    ) : (
                      allDoctors.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Button variant="text" startIcon={<ReplayIcon />} onClick={handleReset}>
                Reset
              </Button>
            </Box>
          </Stack>
        </Grid>

        {loading ? (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={12} lg={12}>
              <Grid container spacing={2}>
                {cardsData.map((card) => (
                  <Grid item xs={12} sm={6} md={4} key={card.id}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5">{card.title}</Typography>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                          {card.icon}
                          <Typography variant="body1">{card.description}</Typography>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default DmrAnalysis;
